import React, { useState, useEffect } from "react";
import "../../styles/component/pages/BlogPage.css";
import { Link } from "react-router-dom";
import { getPosts } from "../../services/posts"; // path may differ based on your folder structure

const BlogPage = () => {
  const [selectedTag, setSelectedTag] = useState("");
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const postsData = await getPosts();
      setPosts(postsData);
    }
    fetchPosts();
  }, []);

  const tags = [
    ...new Set(
      posts.flatMap((post) =>
        post.data && Array.isArray(post.data.tags) ? post.data.tags : []
      )
    ),
  ];

  const filteredPosts = selectedTag
    ? posts.filter((post) => post.data.tags.includes(selectedTag))
    : posts;

  return (
    <div className="blog-container">

      <div className="content-container">
        <div className="tags-container">
          <h2>Tags</h2>
          {tags.map((tag) => (
            <button key={tag} className="tag-button" onClick={() => setSelectedTag(tag)}>
              {tag}
            </button>
          ))}
        </div>
        <div className="blog-list">
          {filteredPosts.map((post, index) => {
            const previewContent = post.content.props.children.slice(0, 3);
            return (
              <div key={index} className="blog-post">
                <h2>{post.data.title}</h2>
                <p className="date">{post.data.created}</p>
                <div className="tags">
                  {post.data.tags.map((tag) => (
                    <span key={tag}>{tag}</span>
                  ))}
                </div>
                <div className="blog-preview">
                  {previewContent}
                  <Link className="continue-reading" to={`/posts/${post.id}`}>
                    Continue reading
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;