import React from "react";
import { Link } from "react-router-dom";
import "../../styles/global/App.css";

const HomePage = () => {
  const startYear = 2002;
  const currentYear = new Date().getFullYear();
  const yearsOfExperience = currentYear - startYear;

  // Obfuscated email
  const email = "ten.samohtcire@cire".split("").reverse().join("");

  return (
    <div className="homepage">
      <h1>Hello, I'm Eric</h1>

      <div className="about-me">
        <p>
          Whether as an individual contributor, a member of a team, or as part
          of a larger community, I'm passionate about {" "}
          <span className="highlighted">architecting</span>, {" "}
          <span className="highlighted">engineering</span>, {" "}
          <span className="highlighted">developing</span>, and{" "}
          <span className="highlighted">deploying</span>{" GenAI "}
          <span className="highlighted">apps</span>, {" "}
					<span className="highlighted">automations</span> and{" "}
          <span className="highlighted">agents</span> to drive
          transformation.
        </p>
      </div>

      <h3>Let's put GenAI to work!</h3>
      <br></br>

      <h2>Technical Skills and Interests</h2>
      <div className="skills-grid">
        <div>Real-Time Voice and Video APIs</div>
        <div>Unstructured Data Pipelines</div>
        <div>Knowledge Graphs</div>
        <div>RAG Pipelines</div>
        <div>Serverless Automations, Workflows and Agents</div>
        <div>ReactJS, Streamlit, Dash, Plotly and Leaflet Web UIs</div>
        <div>Azure Python SDKs</div>
        <div>Entra ID auth</div>
        <div>Requirements Elicitation</div>
        <div>Azure Resource Deployment and Admin</div>
        <div>Static Site Generators</div>
      </div>

      <h2>Contact</h2>
      <div className="contact-section">
        <p>
          Feel like chatting about GenAI or other interesting things? Ping me!
        </p>
        <div className="contact-grid">
          <a href={`mailto:${email}`}>Email</a>
          <Link to="https://mvp.microsoft.com/en-us/PublicProfile/5003791?fullName=Eric%20Thomas">
            MVP Profile
          </Link>
          <Link to="https://github.com/seadude">GitHub</Link>
          <Link to="https://www.linkedin.com/in/seadude/">LinkedIn</Link>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
